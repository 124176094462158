import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  compose,
  not,
  reduce,
  map,
  prop,
  pathOr,
  equals,
  reject,
  head,
  anyPass,
  all,
  filter,
  propEq,
  isEmpty,
} from 'ramda'
import { UIBlock, UILayout, UIText, UIIcon, UIButton } from 'bora-material-ui'
import { withRouter } from 'react-router-dom'

// import once from 'lodash.once'

import * as Sentry from '@sentry/browser'

import ActionsForResults from './ActionsForResults'
import { PaymentResultsError } from './PaymentResultsError'
import ConditionsOfTicketsReturn from './ConditionsOfTicketsReturn'

import PrintedTicket from '../../components/PrintedTicket'
import RefundReason from '../../components/RefundReason'
import { TicketPending } from './TicketPending'

import UIElements from '../../../../consts/UIElements'
import messages from '../../../../consts/messages'

import { actions, selectors } from '../../provider'
import { isRingsuOrLaaksaareSail } from '../../../../services/reservation/selectors.util'
import { getBookingConditionsForRoute } from '../../../../services/reservation/selectors'
import { getStyle, isLiinilaevad } from '../../../../utils/liinilaevadThemeUtils'
// import { THEME } from 'bora-material-ui/themes'

const statusFromSail = pathOr('', ['sailRefs', 0, 'status'])
export const expiredFromSail = pathOr(false, ['sailRefs', 0, 'expired'])
export const expiredSinceFromSail = pathOr(0, ['sailRefs', 0, 'expireCancelledSailsInXDays'])
export const getFirstRoute = pathOr('', ['sailPackages', 0, 'sailRefs', 0, 'route'])

const isOpen = equals('OPEN')
const isClosed = equals('CLOSED')
const isCheckin = equals('CHECK-IN')
const isTraveled = equals('TRAVELLED')
const isCancelledStatus = equals('CANCELLED')

export const isStatusOfSailCancelled = compose(isCancelledStatus, pathOr('', ['sailRefs', 0, 'status']))

const notOpen = compose(not, isOpen)
const notClosed = compose(not, isClosed)
const notCheckin = compose(not, isCheckin)
const notTraveled = compose(not, isTraveled)
const onlyStatuses = map(prop('status'))

export const areThereAnyCancelledTrips = compose(
  reduce(anyPass([notOpen, notClosed, notCheckin, notTraveled]), false),
  onlyStatuses
)

export const areAllTripsCancelled = compose(all(isCancelledStatus), onlyStatuses)

const firstNotClosedNoOpenStatus = compose(
  head,
  reject(isClosed),
  reject(isOpen),
  reject(isCheckin),
  reject(isTraveled)
)

const xsStyles = {
  wrapper: { margin: '0', width: 'auto' },
  wrap: { 'flex-wrap': 'wrap' },
}

export const allowedStatuses = ['OK', 'CN', 'BOOKED']
const canShowReservationForStatus = (status = '') => allowedStatuses.includes(status)

const CancelledTripBanner = styled(UILayout)`
  margin-bottom: 1em;
`

class PaymentResults extends React.Component {
  static contextTypes = {
    muiTheme: PropTypes.object,
  }

  static propTypes = {
    goToPage: PropTypes.func.isRequired,
    goToRefund: PropTypes.func.isRequired,
    goToEditReservation: PropTypes.func.isRequired,
    startEditReservation: PropTypes.func.isRequired,
    manageUrlReservation: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired,
    urlReservationToken: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    shouldDisabledRemoveForRoute: PropTypes.bool,
    reservation: PropTypes.object,
    trips: PropTypes.array,
    viewMode: PropTypes.any,
    setViewMode: PropTypes.func.isRequired,
  }

  static defaultProps = {
    viewMode: false,
    reservation: {},
    trips: [],
    shouldDisabledRemoveForRoute: false,
  }

  componentDidMount() {
    // note for payments:
    // here we need to load the reservation by the id provided (done)
    // now we need to now the transaction id which can be stored in the reservation ? (ask Igor)
    // apparently we need to ask (every n interval, may be use rxjs lite (?))
    // the transaction status endpoint for any good or bad status
    // to fully render the ticket or in bad case redirect to confirm with the provided reservation id param
    const { manageUrlReservation, location } = this.props

    manageUrlReservation({
      urlSearchParams: location.search,
      reservationToken: selectors.getReservationIdFromUrl(this.props),
    })
  }

  componentWillUnmount() {
    this.props.setViewMode('')
  }

  render() {
    const { muiTheme } = this.context
    const {
      locale,
      reservation,
      trips,
      viewMode,
      urlReservationToken,
      fetching,
      isLoggedIn,
      forceShowEdit,
      shouldDisabledRemoveForRoute = false,
      outOfBookingBoundary = false,
    } = this.props

    const currentReservation = this.props.currentReservation || {}

    if (isEmpty(reservation)) {
      return null
    }

    const currentReservationInEqOrTrue = currentReservation.token
      ? currentReservation.token === urlReservationToken
      : true

    const reservationNotPayed =
      !fetching && currentReservationInEqOrTrue && !canShowReservationForStatus(reservation.status)

    const reservationNotExist =
      !fetching && currentReservation.token !== urlReservationToken && reservation.token !== urlReservationToken
    const showTickets = (!fetching && !reservationNotPayed && !reservationNotExist) || viewMode === 'cancelled'

    const reservationIsBooked = reservation.status === 'BOOKED'
    const anyCancelledTrips = areThereAnyCancelledTrips(trips)
    const cancelledTrips = areAllTripsCancelled(trips)

    const daysExpired = compose(pathOr(0, [0, 'expiredSince']), filter(propEq('status', 'EXPIRED')))(trips)
    let notOpenNotClosedTripStatus = anyCancelledTrips && compose(firstNotClosedNoOpenStatus, onlyStatuses)(trips)

    if (notOpenNotClosedTripStatus && reservationIsBooked) {
      notOpenNotClosedTripStatus = 'BOOKED_CANCELLED'
    }
    const reservationCancelled = viewMode === 'cancelled'

    let errorReason = reservationNotPayed
      ? `Reservation ${urlReservationToken} NOT PAYED, cannot show ticket with status ${reservation.status}`
      : ''
    if (reservationNotExist) {
      Sentry.captureMessage(
        `Frontend thinks, this reservation (${urlReservationToken}) does not exist,
        current reservation : ${currentReservation.token}`
      )
      errorReason = `Reservation ${urlReservationToken} does not exist, cannot show ticket
       ${currentReservation.token} / ${urlReservationToken}`
    }

    const isLaaksaare = getFirstRoute(reservation).includes('LAA')
    const isRingsu = getFirstRoute(reservation).includes('RIN')

    let headerTranslationKey = messages.paymentSucceeded
    if ((isLaaksaare && !outOfBookingBoundary) || isRingsu || reservation.status === 'BOOKED') {
      headerTranslationKey = messages.bookSucceeded
    }

    if (isLaaksaare && !outOfBookingBoundary) {
      headerTranslationKey = messages.paymentSucceededLaak
    }

    return (
      <UILayout
        column
        j-flex-start
        padding={UIElements.CONTENT_PADDING}
        margin={getStyle('0', `${UIElements.HEADER ? '-97px' : '40px'} 0 0`)}
        width={UIElements.CONTENT_WIDTH}
        xs={xsStyles.wrapper}
        {...muiTheme.ids.mainInsideContent}
      >
        <TicketPending display-if={reservationNotPayed} reservationToken={urlReservationToken} />
        <UIBlock width="100%" margin="0 auto 0 0" bgColor="#ffffff">
          <PaymentResultsError reason={errorReason} display-if={viewMode !== 'cancelled' && reservationNotExist} />
          <Fragment display-if={showTickets}>
            <UILayout column center xs={0} minHeight="100px" {...muiTheme.ids.mainInside} display-if={!viewMode}>
              <UILayout column center j-flex-center padding="10px 20px">
                <UIText textTransform="uppercase" size="24px" translate={messages.thanks} {...muiTheme.ids.thanks} />
                <UIText size="18px" translate={headerTranslationKey} {...muiTheme.ids.thanks} />
              </UILayout>
            </UILayout>

            <UILayout column padding={getStyle('0 0 20px 0', '20px 20px')} {...muiTheme.ids.ticketWrapper}>
              <UILayout
                display-if={!reservationCancelled}
                xs={xsStyles.wrap}
                j-flex-space-between={!isLiinilaevad}
                flexDirection={getStyle('column-reverse')}
                data-testid="reservation-toolbar"
              >
                <ActionsForResults
                  locale={locale}
                  isLoggedIn={isLoggedIn}
                  reservation={reservation}
                  goToPage={this.props.goToPage}
                  goToRefund={this.props.goToRefund}
                  goToEditReservation={this.props.goToEditReservation}
                  startEditReservation={this.props.startEditReservation}
                  // Will be removed after successful tests
                  showEdit={forceShowEdit}
                  hideRemove={shouldDisabledRemoveForRoute && !outOfBookingBoundary && cancelledTrips}
                  // Will be uncommented after successful tests
                  // showEdit={forceShowEdit && muiTheme.name !== THEME.SAAREMAA}
                  // hideRemove={(shouldDisabledRemoveForRoute && cancelledTrips) || muiTheme.name === THEME.SAAREMAA}
                />
                {anyCancelledTrips && (
                  <CancelledTripBanner>
                    <RefundReason
                      reason={{ status: notOpenNotClosedTripStatus, daysPassed: daysExpired }}
                      reservation={reservation}
                      goToRefund={this.props.goToRefund}
                      goToEditReservation={this.props.goToEditReservation}
                      startEditReservation={this.props.startEditReservation}
                      noRefundForRoute={shouldDisabledRemoveForRoute && !outOfBookingBoundary}
                      isLaaksaare={isLaaksaare}
                      outOfBookingBoundary={outOfBookingBoundary}
                    />
                  </CancelledTripBanner>
                )}
              </UILayout>

              <PrintedTicket
                trips={trips}
                reservation={reservation}
                isCancelled={reservationCancelled}
                outOfBookingBoundary={outOfBookingBoundary}
              />

              <ConditionsOfTicketsReturn display-if={!reservationCancelled} />

              <UILayout
                display-if={false}
                padding="0px 0px 20px 0px"
                column
                center
                j-flex-center
                borderBottom="solid 1px #bfc3c7"
              >
                <UIButton type="circle" width="50px" height="50px">
                  <UIIcon type="add" />
                </UIButton>
                <UIText
                  margin="10px 0px 0px 0px"
                  textTransform="uppercase"
                  size="18px"
                  color="#79909b"
                  translate={messages.buyMoreTickets}
                />
              </UILayout>
            </UILayout>
          </Fragment>
        </UIBlock>
      </UILayout>
    )
  }
}

const mapStateToProps = (state, props) => {
  const reservationToken = selectors.getReservationIdFromUrl(props)
  const { viewMode } = state
  let reservation = viewMode !== 'cancelled' ? selectors.getPayedReservation(state)(reservationToken) : state.cancelled
  if (
    state.reservation.current &&
    isRingsuOrLaaksaareSail(state.reservation.current.sailPackages) &&
    state.reservation.current.saleChannel === 'BACK OFFICE'
  ) {
    reservation = state.reservation.current
  }

  const route = getFirstRoute(reservation)
  const shouldDisabledRemoveForRoute = selectors.getRouteNoRefundIfCancelled(route)(state)

  const trips = selectors
    .tripsFromReservation(state)(reservation)
    .map((trip) => ({
      ...trip,
      status: expiredFromSail(trip) ? 'EXPIRED' : statusFromSail(trip),
      expired: expiredFromSail(trip),
      expiredSince: expiredSinceFromSail(trip),
    }))

  const currentReservation = selectors.getCurrentReservation(state)

  return {
    reservation,
    urlReservationToken: reservationToken,
    locale: selectors.getLocale(state),
    isLoggedIn: selectors.isLoggedInSelector(state),
    fetching: selectors.getReservationFetching(state),
    currentReservation,
    trips,
    viewMode,
    forceShowEdit: true,
    shouldDisabledRemoveForRoute,
    outOfBookingBoundary: getBookingConditionsForRoute(state),
  }
}

const enhance = compose(connect(mapStateToProps, actions), withRouter)

export default enhance(PaymentResults)
