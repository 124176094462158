import React, { Fragment } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues, change } from 'redux-form'
import { pathOr, map, toUpper, isEmpty, defaultTo, propEq, compose, filter, propOr, any, equals } from 'ramda'

import CircularProgress from '@material-ui/core/CircularProgress'
import { UIText, UIIcon, UILayout, UIButton } from 'bora-material-ui'

import MessageBox from '../../../../Common/components/MessageBox'
import {
  FormLayout,
  VehicleConfirmButton,
  VehicleTicket,
  VehicleParam,
  VehicleAdditionParams,
  VehicleHandicappedParam,
  VehicleCompanyRegNumberParam,
} from '../components'

import { selectors } from '../../../provider'

import { PromoCodeField } from '../../../../../formComponents'

import messages from '../../../../../consts/messages'
import { debouncePromise } from '../../../../../utils/ramda-extend'
import { plateNumberRegexp, onlyAlphaNumericDash } from '../../../../../utils/validate'
import { translate } from '../../../../Common/Translator'
import { changeItemQtty, selectIntermediatePrice } from '../../../../../services/user-selections'
import { vehicleTranslations } from '../../../../../services/pricing/reducers/selectors'
import {
  getReservationFromReserve,
  getVehiclePlateNumbers,
  isCurrentResLaaksaareRoute,
  isEditResLaaksaareRoute,
} from '../../../../../services/reservation/selectors'
import { isLaaksaareInSelectedSails } from '../../../../../services/schedule/reducers'
import { getLastRequest, getNetworkErrorResult } from '../../../../../reducers/network'
import {
  getEnableCompanyLoyaltiesForRoute,
  getEnableHandicappedVehiclesForRoute,
} from '../../../../../services/routes/routes/reducers'
import { knownErrors } from '../../../../Common/known-errors'
import VehicleEditManuallyButton from '../components/VehicleEditManuallyButton'
import VehicleInputWeight from '../components/VehicleInputWeight'
import { formatRouteTitle } from '../../../../../utils/strings'
import { getDataFromURL } from '../../../../../services/utils'
// REMOVE_PROMOTION_ITEM_DELAY removed temporarilly for backend feature
import { SAIL_FROM_URL_PREFIX } from './consts'

const anyAdditiononlFeatureEnabled = any(equals(true))

export const filterItemsByType = (subType) => compose(filter(propEq('priceCategorySubType', subType)), defaultTo([]))

const styles = {
  formWrapper: { minHeight: '370px', margin: '10px 0 15px 0', 'flex-wrap': true },
  formWrapperEdit: {
    minHeight: '370px',
    margin: '10px 0 15px 0',
    'flex-wrap': false,
    flexDirection: 'column',
    align: 'center',
  },
  width100: { width: '100%' },
  padding0: { padding: '0' },
  padding0Width100: { padding: '0', width: '100%' },
  marginv20h10: { margin: '20px 10px' },
  marginv20h0: { margin: '20px 10px' },
  flexStart: { 'flex-start': true },
  marginv0h10: { margin: '0 10px' },
  marginv10h0: { margin: '10px auto' },
  marginv16Width100: { width: '100%', margin: '16px 0' },
  colorWhite: { color: 'white' },
  colorFailed: { color: '#b42a2a' },
  inputManuallyDescrXs: { margin: '16px 10px 0 10px' },
}

const getTransportUiError = (code, formType = '', handleInactiveRegisterCallback = () => {}) => {
  if (code in knownErrors) {
    return translate(messages[knownErrors[code]])
  }

  const intlCode = formType === 'vehiclesForm' ? 'trailerWrongFormError' : 'vehicleWrongFormError'
  const errors = {
    INAPPROPRIATE_UI_FORM: translate(messages[intlCode]),
  }
  if (code === 'VEHICLE_REGISTER_FAILURE') {
    handleInactiveRegisterCallback()
  }
  return errors[code] || translate(messages.vehiclesDefaultError)
}

const getError = (form, code, callback) => getTransportUiError(code, form, callback)

const DEBOUNCE_TIMEOUT = 1800
const checkPlateNumberDebounced = debouncePromise(axios.get, DEBOUNCE_TIMEOUT)

// eslint-disable-next-line max-lines-per-function,complexity
const SimplifiedForm = (
  {
    changeField,
    vehiclesFormValues,
    confirm,
    selectedSails,
    selectedPackage,
    selectedSailPackages,
    reservedSailPackages,
    editMode,
    onGoToManuallyFormAction,
    formName,
    routeCoefficientsMap,
    locale,
    selectedSailPackageIndexToEdit,
    reservationId,
    selectedRoute,
    changeItemQtty,
    isIntermediatePrice,
    changeReduxFormField,
    vehicleTitles,
    vehicleItems,
    trailerItems,
    isLaaksaare,
    legitVehicleInfo = false,
    editDataRequestAllocateError = false,
    vehicleNumbers = [],
    isCompanyLoyaltiesEnabled = false,
    isHandicappedVehiclesEnabled = false,
    isEditingWeightShown = false,
    handleShowEditWeight,
    isAddButtonIsWaitingForResponse = false,
    handleAddButtonDisabled,
    currentVehicles,
    lastReqError,
    isPluralWeightToBeReset,
    handlePluralWeightReset,
    handleInactiveRegisterCallback,
    shouldReplaceVehicleWidth = false,
    // commented temporarily for backend feature
    // currentItems,
    // isReservationForDriverFromReserve,
  },
  { intl = false }
) => {
  const sailRefId = selectedSails[selectedPackage]
  const sailRefIdFromUrl = getDataFromURL(SAIL_FROM_URL_PREFIX).slice(SAIL_FROM_URL_PREFIX.length)
  const routeTitles = reservedSailPackages
    ? reservedSailPackages.map((item) => item.sailRefs[0].routeLegTitle)
    : selectedSailPackages.map((item) => item.legSequence[0].title)

  const shouldShowAdditionalFeatures = anyAdditiononlFeatureEnabled([
    isCompanyLoyaltiesEnabled,
    isHandicappedVehiclesEnabled,
  ])

  const {
    selectedSavedVehicle,
    plateNumberVehicle,
    vehicleTicket,
    vehicleSimplified,
    companyRegistrationNumber,
    manuallyChangedWeights,
  } = vehiclesFormValues

  const uiFormType = formName === 'vehiclesForm' ? 'VEHICLE' : 'TRAILER'
  const selectedVehicle = plateNumberVehicle || selectedSavedVehicle

  const currentVehiclesWithType = currentVehicles.filter(
    (item) => item.subType === uiFormType && selectedVehicle && item.licencePlate === selectedVehicle.licensePlate
  )
  const weightsFromReservation = currentVehiclesWithType
    ? currentVehiclesWithType
        .map((item) => {
          if (item.weightOnSails) {
            return item.weightOnSails.map((it) => ({
              ...it,
              ...(item.weightOnSails.length > 1
                ? { initialRoundTripCreation: true }
                : { initialRoundTripCreation: false }),
            }))
          } else if (!item.weightOnSails && (sailRefIdFromUrl || selectedSailPackageIndexToEdit)) {
            return {
              weightInKg: item.weightInKg,
              sailRefId: item.sailRefId,
              initialRoundTripCreation: false,
            }
          }
          return []
        })
        .flat()
    : undefined

  let routeTitlesWithExistedWeights = []
  if (weightsFromReservation && weightsFromReservation.length) {
    routeTitlesWithExistedWeights = reservedSailPackages.map((item) => {
      let weightToRender =
        weightsFromReservation.find((it) => it.sailRefId === item.sailRefs[0].sailRefId) &&
        weightsFromReservation.find((it) => it.sailRefId === item.sailRefs[0].sailRefId).weightInKg
      if (
        currentVehicles.filter(
          (item) => item.subType === uiFormType && selectedVehicle && item.licencePlate === selectedVehicle.licensePlate
        ).length > 1
      ) {
        const weightsForCurrentSailRef =
          weightsFromReservation.find((it) => it.sailRefId === item.sailRefs[0].sailRefId) &&
          weightsFromReservation.filter((it) => it.sailRefId === item.sailRefs[0].sailRefId)

        if (weightsForCurrentSailRef) {
          const modifiedWeightForRoundTrip = weightsForCurrentSailRef.find(
            (item) => item.initialRoundTripCreation === false
          )
          weightToRender = modifiedWeightForRoundTrip
            ? modifiedWeightForRoundTrip.weightInKg
            : weightsForCurrentSailRef.find((item) => item.initialRoundTripCreation === true).weightInKg
        } else if (selectedVehicle) {
          weightToRender = selectedVehicle.weightInKg
        }
      }
      return {
        weight: weightToRender,
        title: item.sailRefs[0].routeLegTitle,
        sailRefId: item.sailRefs[0].sailRefId,
      }
    })
  }

  const vehicleStatus = pathOr('', ['status'], selectedVehicle)

  const companyStatus = pathOr('', ['status'], companyRegistrationNumber)
  const errorCode = pathOr('', ['code'], selectedVehicle)

  const { priceCategory, licensePlate, coefficients = [] } = plateNumberVehicle || {}

  let vehicleTitleTranslatedFromPrices = vehicleTitles[priceCategory]

  const [vehicle] = vehicleItems
  if (vehicle) {
    vehicleTitleTranslatedFromPrices = vehicleTitles[vehicle.priceCategory]
  }

  const coefficientsText = [
    `${routeCoefficientsMap.header}\n`,
    ...map((code) => {
      if (code.includes('SOR-TRI-SOR_WIDTH-COEF') && !shouldReplaceVehicleWidth) {
        return routeCoefficientsMap[`${code}_${toUpper(locale)}_BEFORE_01.03.2022`]
      }
      return routeCoefficientsMap[`${code}_${toUpper(locale)}`]
    }, coefficients),
  ].join('\n')

  let vehicleNotAdded

  if (uiFormType === 'TRAILER') {
    vehicleNotAdded = editMode ? false : isEmpty(trailerItems)

    if (editDataRequestAllocateError) {
      if (isEmpty(trailerItems)) vehicleNotAdded = true
      else {
        vehicleNotAdded = !vehicleNumbers.includes(propOr('', 'licensePlate')(plateNumberVehicle))
      }
    }
  } else {
    vehicleNotAdded = editMode ? false : isEmpty(vehicleItems)
    // eslint-disable-next-line no-lonely-if
    if (editDataRequestAllocateError) {
      if (isEmpty(vehicleItems)) vehicleNotAdded = true
      else {
        vehicleNotAdded = !vehicleNumbers.includes(propOr('', 'licensePlate')(plateNumberVehicle))
      }
    }
  }

  const addButtonShouldBeEnabled = vehicleNotAdded || !vehicleSimplified || vehicleSimplified !== licensePlate
  const getVehicleQueryParams = ({ licensePlate, optionalQueryParams }) => {
    const params = {
      ...(reservationId && { reservationId }),
      uiFormType,
      route: selectedRoute,
      ...optionalQueryParams,
    }
    if (isHandicappedVehiclesEnabled) {
      params.isHandicapped = Boolean(vehiclesFormValues.handicapped)
    }

    if (!(licensePlate && sailRefId)) throw new Error('Checking license place url is not valid')

    const queryParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&')
    return `/api/vehicle/${licensePlate}/${sailRefId}/erd${queryParams.length ? '?' : ''}${queryParams}`
  }

  const updatePlateNumberVehicle = (vehicle) => {
    changeField('plateNumberVehicle', {
      priceCategory: vehicle.price.priceCategory,
      code: vehicle.price.priceCategory,
      status: 'verified',
      price: vehicle.price.price,
      coefficients: pathOr([], ['price', 'coefficients'], vehicle),
      descr: vehicle.price.priceCategoryTitle,
      vehicleType: vehicle.vehicleType,
      weightEditAllowed: vehicle.weightEditAllowed,
      manuallyChangedWeights: vehiclesFormValues.manuallyChangedWeights,
      ...vehicle.vehicleInfo,
    })
  }
  const handleNumberPlateChange = async (value, string) => {
    if (plateNumberRegexp.test(string)) {
      const licensePlate = string.toUpperCase()
      if (isEditingWeightShown) {
        handleShowEditWeight()
      }
      if (isAddButtonIsWaitingForResponse) {
        handleAddButtonDisabled()
      }
      try {
        changeField('companyRegistrationNumber', null)
        changeField('plateNumberVehicle', { status: 'pending' })
        const url = getVehicleQueryParams({ licensePlate })
        const vehicle = await checkPlateNumberDebounced(url)
        updatePlateNumberVehicle(vehicle)
      } catch (e) {
        changeField('plateNumberVehicle', { status: 'failed', code: e.data && e.data.code })
      }
    } else if (plateNumberVehicle) {
      changeField('plateNumberVehicle', null)
    }
  }

  const handleCompanyNumberCheck = async (companyRegistrationNumber) => {
    try {
      const optionalQueryParams = { ...(companyRegistrationNumber && { companyRegistrationNumber }) }
      changeField('companyRegistrationNumber', { status: 'pending', companyRegistrationNumber })
      const { licensePlate } = { ...plateNumberVehicle }
      const vehicle = await axios.get(getVehicleQueryParams({ licensePlate, optionalQueryParams }))
      updatePlateNumberVehicle(vehicle)
      changeField('companyRegistrationNumber', { status: 'verified', companyRegistrationNumber })
    } catch (e) {
      changeField('plateNumberVehicle', { ...plateNumberVehicle, code: e.data.code })
      changeField('companyRegistrationNumber', { status: 'failed' })
    }
  }

  const handleWeightChange = (weights = [0]) => {
    changeField('manuallyChangedWeights', weights)
  }

  const handleHandicappedChange = () => {
    vehiclesFormValues.handicapped = !vehiclesFormValues.handicapped
    changeField('handicapped', vehiclesFormValues.handicapped)
    handleNumberPlateChange(null, plateNumberVehicle.licensePlate).catch((err) => console.error(err))
  }
  // commented out temporarily for backend feature
  /* const getPromotionItemToRemoveWithVehicle = (items) => {
    if (!items) return undefined
    return items.find((item) => item.promotion)
  }*/

  const handleCloseClick = () => {
    changeItemQtty({
      ...vehicleTicket,
      count: 0,
      subType: 'VEHICLE',
      type: 'vehicle-close-icon',
      formType: uiFormType,
      ...(sailRefIdFromUrl && { sailRefId: sailRefIdFromUrl }),
    })
    // commented out temporarily for backend feature
    /* if (isReservationForDriverFromReserve && Boolean(getPromotionItemToRemoveWithVehicle(currentItems))) {
      setTimeout(() => {
        changeItemQtty({
          ...getPromotionItemToRemoveWithVehicle(currentItems),
          code: getPromotionItemToRemoveWithVehicle(currentItems).priceCategory,
          count: -1,
          type: 'dropdown',
        })
      }, REMOVE_PROMOTION_ITEM_DELAY)
    }*/
    if (isPluralWeightToBeReset) {
      handlePluralWeightReset()
    }
    changeReduxFormField('ticketsForm', `${formName.substring(0, 7)}IsActive`, false)
    changeField('formMayBeClosed', true)
  }

  if (lastReqError && isAddButtonIsWaitingForResponse) {
    handleAddButtonDisabled()
  }

  let routeTitlesForManualWeight = editMode
    ? [routeTitles[selectedSailPackageIndexToEdit]]
    : sailRefIdFromUrl && routeTitlesWithExistedWeights && routeTitlesWithExistedWeights.length
    ? routeTitlesWithExistedWeights
        .filter((item) => item.sailRefId === Number(sailRefIdFromUrl))
        .map((item) => item.title)
    : routeTitles
  if (
    sailRefIdFromUrl &&
    routeTitlesWithExistedWeights &&
    routeTitlesWithExistedWeights.length < 1 &&
    reservedSailPackages
  ) {
    const routeTitleAfterRemoving =
      reservedSailPackages.find((item) => item.sailRefs[0].sailRefId === Number(sailRefIdFromUrl)) &&
      reservedSailPackages.find((item) => item.sailRefs[0].sailRefId === Number(sailRefIdFromUrl)).sailRefs[0]
        .routeLegTitle
    routeTitlesForManualWeight = [routeTitleAfterRemoving]
  }

  return (
    <FormLayout additionalStyles={!editMode ? styles.formWrapper : styles.formWrapperEdit} editMode={editMode}>
      <UILayout column width={!editMode ? '30%' : '380px'} justify="flex-start" sm={styles.width100}>
        <UIText
          display-if={!(editMode && vehicleTicket)}
          align="left"
          size="18px"
          color="#79909b"
          translate={messages.estonianPlateNumberDescr}
          sm={styles.marginv20h10}
        />
        <UILayout position="relative" sm={styles.marginv10h0} margin="16px 0" width="100%" maxWidth="350px">
          <PromoCodeField
            name="plateNumber"
            data-test="vehicle-place-number"
            data-testid="vehicle-place-number"
            placeholder={intl.formatMessage(messages.licensePlateNumber)}
            mask="999aaa"
            onFocus={() => {}}
            onBlur={() => {}}
            value={plateNumberVehicle && plateNumberVehicle.code}
            wrapperStyles={styles.padding0}
            inputStyle={{ 'text-transform': 'uppercase', ...(vehicleStatus === 'failed' && styles.colorFailed) }}
            normalize={onlyAlphaNumericDash}
            onChange={handleNumberPlateChange}
            inputWrapperStyles={styles.padding0}
            disabled={vehicleTicket}
          />
          <UILayout position="relative" right="0" top="-5px" width="auto">
            <UIButton
              display-if={vehicleStatus !== 'pending' && vehicleStatus !== 'verified'}
              type="circle"
              width="56px"
              height="56px"
            >
              <UIIcon fill="white" type="iconQuestion" />
            </UIButton>

            <UILayout
              display-if={vehicleStatus === 'pending'}
              center
              j-flex-center
              borderRadius="56px"
              width="56px"
              height="56px"
              bgColor="gray"
            >
              <CircularProgress size={30} style={styles.colorWhite} />
            </UILayout>

            <UIButton
              display-if={vehicleStatus === 'verified'}
              type="circle"
              background="#7ed321"
              height="56px"
              width="56px"
              disabled={vehicleTicket}
            >
              <UIIcon top="20px" fill="white" type="check" />
            </UIButton>
          </UILayout>
        </UILayout>
        <UIText
          display-if={vehicleStatus !== 'verified' && vehicleStatus !== 'failed'}
          align="left"
          size="14px"
          color="#79909b"
          translate={messages.descrOfTD}
          sm={styles.marginv20h10}
        />
        <UIText
          display-if={vehicleStatus === 'failed'}
          align="left"
          size="14px"
          color="#b42a2a"
          formattedMessage={getError(formName, errorCode, handleInactiveRegisterCallback)}
          onAction={onGoToManuallyFormAction}
        />
        <UILayout
          column
          display-if={selectedVehicle && vehicleStatus === 'verified'}
          sm={styles.marginv20h0}
          data-test="estonian-cars-params"
        >
          <UIText
            weight="bold"
            align="left"
            size="18px"
            color="#79909b"
            translate={
              legitVehicleInfo || selectedVehicle.category ? messages.dataFromTDDescr : messages.dataFromTheClientDescr
            }
            sm={styles.marginv0h10}
          />
          <UILayout column center margin="10px 0px 10px 0px" sm={styles.flexStart}>
            <VehicleParam label={translate(messages.vehicleType)} value={selectedVehicle.category || '-'} />
            <VehicleParam label={translate(messages.length)} value={`${selectedVehicle.lengthInMm} mm`} />
            <VehicleParam label={translate(messages.width)} value={`${selectedVehicle.widthInMm} mm`} />
            <VehicleParam label={translate(messages.height)} value={`${selectedVehicle.heightInMm} mm`} />
            <VehicleParam
              label={
                (manuallyChangedWeights && manuallyChangedWeights.length > 1) ||
                (routeTitlesWithExistedWeights &&
                  routeTitlesWithExistedWeights.length > 1 &&
                  !manuallyChangedWeights &&
                  !isPluralWeightToBeReset)
                  ? translate(messages.weights)
                  : translate(messages.weight)
              }
              value={
                isEditingWeightShown ||
                (selectedVehicle && selectedVehicle.weightEditAllowed && manuallyChangedWeights) ||
                (vehicleTicket &&
                  vehicleTicket.weightEditAllowed !== false &&
                  routeTitlesWithExistedWeights &&
                  routeTitlesWithExistedWeights.length > 0)
                  ? ''
                  : `${selectedVehicle.weightInKg} kg`
              }
            />
            {!isEditingWeightShown &&
              selectedVehicle &&
              selectedVehicle.weightEditAllowed &&
              manuallyChangedWeights &&
              manuallyChangedWeights.map((weight, index) => (
                <Fragment>
                  <VehicleParam
                    label={
                      selectedSailPackageIndexToEdit
                        ? formatRouteTitle(routeTitles[selectedSailPackageIndexToEdit])
                        : routeTitlesForManualWeight.length >= 1 && sailRefIdFromUrl
                        ? formatRouteTitle(
                            routeTitlesForManualWeight.find((item) => routeTitles.find((it) => item === it))
                          )
                        : formatRouteTitle(routeTitles[index])
                    }
                    value={`${weight} kg`}
                  />
                </Fragment>
              ))}
            {!isEditingWeightShown &&
            !manuallyChangedWeights &&
            editMode &&
            vehicleSimplified &&
            !addButtonShouldBeEnabled &&
            currentVehicles &&
            currentVehicles.find((item) => item.licencePlate === selectedVehicle.licensePlate) &&
            vehicleTicket &&
            routeTitlesWithExistedWeights &&
            routeTitlesWithExistedWeights.length &&
            routeTitlesWithExistedWeights ? (
              <VehicleParam
                label={formatRouteTitle(routeTitlesWithExistedWeights[selectedSailPackageIndexToEdit].title)}
                value={`${routeTitlesWithExistedWeights[selectedSailPackageIndexToEdit].weight} kg`}
              />
            ) : null}
            {!isEditingWeightShown &&
              !manuallyChangedWeights &&
              !editMode &&
              currentVehicles &&
              currentVehicles.find((item) => item.licencePlate === selectedVehicle.licensePlate) &&
              vehicleTicket &&
              routeTitlesWithExistedWeights &&
              routeTitlesWithExistedWeights.map((item) => (
                <Fragment>
                  {typeof item.weight !== 'undefined' ? (
                    <VehicleParam label={formatRouteTitle(item.title)} value={`${item.weight} kg`} />
                  ) : null}
                </Fragment>
              ))}
          </UILayout>
        </UILayout>
        <VehicleEditManuallyButton
          display-if={selectedVehicle && selectedVehicle.weightEditAllowed && !isEditingWeightShown}
          enabled={
            !vehicleSimplified ||
            (lastReqError && addButtonShouldBeEnabled && !isEditingWeightShown && !isAddButtonIsWaitingForResponse)
          }
          onClick={handleShowEditWeight}
        />
        <VehicleInputWeight
          display-if={isEditingWeightShown}
          curbWeightInKg={selectedVehicle && selectedVehicle.curbWeightInKg}
          onSave={handleWeightChange}
          onClose={handleShowEditWeight}
          // routeTitles={editMode ? [routeTitles[selectedSailPackageIndexToEdit]] : routeTitles}
          routeTitles={routeTitlesForManualWeight}
        />
      </UILayout>
      <UILayout column width={!editMode ? '70%' : '380px'} align="flex-end" sm={styles.marginv16Width100}>
        <UILayout
          display-if={vehicleStatus === 'verified' || vehicleStatus === 'failedFetchCompany'}
          column
          width={!editMode ? '70%' : '100%'}
          sm={styles.width100}
          data-test="vehicle-params"
        >
          <VehicleTicket
            onClose={handleCloseClick}
            showCloseButton={vehicleTicket && !isIntermediatePrice}
            display-if={selectedVehicle}
            price={selectedVehicle.price}
            description={
              selectedVehicle &&
              (uiFormType === selectedVehicle.vehicleType ||
                (vehiclesFormValues.plateNumber &&
                  selectedVehicle.licensePlate &&
                  vehiclesFormValues.plateNumber.toUpperCase() === selectedVehicle.licensePlate.toUpperCase()))
                ? selectedVehicle.descr
                : vehicleTitleTranslatedFromPrices
            }
            iconType={formName === 'vehiclesForm' ? 'iconCar' : 'trailer'}
            margin="17px 0 17px 0"
            hideZeroPrice={isLaaksaare}
          />
          <VehicleAdditionParams display-if={formName === 'vehiclesForm' && shouldShowAdditionalFeatures}>
            <VehicleHandicappedParam
              display-if={isHandicappedVehiclesEnabled}
              value={vehiclesFormValues.handicapped}
              onChange={handleHandicappedChange}
              disabled={vehicleTicket}
            />
            <VehicleCompanyRegNumberParam
              display-if={isCompanyLoyaltiesEnabled}
              value={companyRegistrationNumber && companyRegistrationNumber.value}
              onClick={handleCompanyNumberCheck}
              disabled={companyStatus === 'pending' || vehicleTicket}
              errorCode={companyStatus === 'failed' && errorCode}
            />
          </VehicleAdditionParams>
          <VehicleCompanyRegNumberParam
            display-if={isCompanyLoyaltiesEnabled && formName === 'trailersForm'}
            value={companyRegistrationNumber && companyRegistrationNumber.value}
            onClick={handleCompanyNumberCheck}
            disabled={companyStatus === 'pending' || vehicleTicket}
            errorCode={companyStatus === 'failed' && errorCode}
          />
          <VehicleConfirmButton
            onClick={() => {
              handleAddButtonDisabled()
              changeField('vehicleTicket', selectedVehicle)
              changeField('vehicleSimplified', selectedVehicle.licensePlate)
              if (sailRefIdFromUrl) {
                changeField('sailRefId', sailRefIdFromUrl)
              }
              confirm({
                ...selectedVehicle,
                plateNumber: selectedVehicle.licensePlate,
                subType: formName === 'vehiclesForm' ? 'VEHICLE' : 'TRAILER',
                count: 1,
                type: 'confirm',
                handicapped: Boolean(vehiclesFormValues.handicapped),
                companyRegistrationNumber: vehiclesFormValues.companyRegistrationNumber,
                manuallyChangedWeights: vehiclesFormValues.manuallyChangedWeights,
                route: selectedRoute,
                uiFormType,
                priceCategory,
                customVehicleParams: {
                  heightInCm: Number(selectedVehicle.heightInMm) / 10,
                  lengthInCm: Number(selectedVehicle.lengthInMm) / 10,
                  weightInKg: selectedVehicle.weightInKg,
                  widthInCm: Number(selectedVehicle.widthInMm) / 10,
                },
                ...(sailRefIdFromUrl && { sailRefId: vehiclesFormValues.sailRefId }),
              })
            }}
            enabled={addButtonShouldBeEnabled && !isEditingWeightShown && !isAddButtonIsWaitingForResponse}
            disabledMessage={
              vehicleSimplified && !addButtonShouldBeEnabled
                ? messages.dataForVehicleWasAdded
                : messages.confirmDataForVehicle
            }
            margin="10px 0"
          />
          {/* <UIText align="left" size="14px" color="#79909b" translate={messages.inputManuallyDescr} xs={styles.inputManuallyDescrXs} /> */}
          <UILayout column display-if={!isEmpty(coefficients)}>
            <MessageBox margin="10px 0 10px 0" text={coefficientsText} type="error" />
          </UILayout>
        </UILayout>
      </UILayout>
    </FormLayout>
  )
}

SimplifiedForm.propTypes = {
  changeField: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  selectedSails: PropTypes.object.isRequired,
  selectedPackage: PropTypes.object.isRequired,
  onGoToManuallyFormAction: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  isLaaksaare: PropTypes.bool,
  vehiclesFormValues: PropTypes.object.isRequired,
  formName: PropTypes.string,
  routeCoefficientsMap: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  reservationId: PropTypes.any.isRequired,
  specialPackageMode: PropTypes.bool,
  isIntermediatePrice: PropTypes.bool,
  legitVehicleInfo: PropTypes.bool,
  editDataRequestAllocateError: PropTypes.bool,
  vehicleNumbers: PropTypes.array,
  isHandicappedVehiclesEnabled: PropTypes.bool,
  isCompanyLoyaltiesEnabled: PropTypes.bool,
  isAddButtonIsWaitingForResponse: PropTypes.bool,
  shouldReplaceVehicleWidth: PropTypes.bool,
  handleAddButtonDisabled: PropTypes.func,
  handleInactiveRegisterCallback: PropTypes.func,
}

SimplifiedForm.defaultProps = {
  editMode: false,
  isLaaksaare: false,
  editDataRequestAllocateError: false,
  legitVehicleInfo: false,
  formName: 'vehiclesForm',
  isIntermediatePrice: false,
  vehicleNumbers: [],
  isHandicappedVehiclesEnabled: false,
  isCompanyLoyaltiesEnabled: false,
  handleInactiveRegisterCallback: () => {},
}

SimplifiedForm.contextTypes = {
  intl: PropTypes.any,
}

export default connect(
  (state, { formName, editMode }) => {
    const selectedRoute = pathOr('', ['userSelections', 'sailPackages', [0], 'route'])(state)
    const currentVehicles = pathOr(null, ['reservation', 'current', 'vehicles'])(state)
    const currentItems = pathOr(null, ['reservation', 'current', 'items'])(state)
    const currentVehiclesFormatted = currentVehicles
      ? currentVehicles.map((item) => ({
          ...item,
          subType:
            (currentItems &&
              currentItems.find((it) => it.ownerSeqNs[0] === item.seqN) &&
              currentItems.find((it) => it.ownerSeqNs[0] === item.seqN).priceCategorySubType) ||
            '',
          sailRefId:
            Number(
              currentItems &&
                currentItems.find((it) => it.ownerSeqNs[0] === item.seqN) &&
                Object.keys(currentItems.find((it) => it.ownerSeqNs[0] === item.seqN).sailRefIdToPricePerSail)[0]
            ) || 0,
        }))
      : []
    const isIntermediatePrice = Boolean(selectIntermediatePrice(state))
    const isLaaksaare = editMode
      ? isEditResLaaksaareRoute(state)
      : isLaaksaareInSelectedSails(state) || isCurrentResLaaksaareRoute(state)

    const lastReqError = getNetworkErrorResult(state) || ''
    const lastRequest = getLastRequest(state)
    const editDataRequestAllocateError = editMode
      ? lastRequest.endsWith('editData') && lastReqError.startsWith('FAILED_TO_ALLOCATE')
      : false

    const { code = '' } = selectors.getFirstSelectedSailPackage(state)
    const sailDate = selectors.getSailDate(state)
    const tripDate = new Date(sailDate)
    const shouldReplaceVehicleWidth = !(code.includes('TRI') && tripDate < Number(new Date('2022/03/01')))

    return {
      vehiclesFormValues: getFormValues(formName)(state) || {},
      selectedSails: state.schedule.selectedSails,
      selectedPackage: pathOr('', ['userSelections', 'sailPackages', [0], 'code'])(state),
      reservedSailPackages: pathOr('', ['reservation', 'current', 'sailPackages'])(state),
      selectedSailPackages: pathOr('', ['userSelections', 'sailPackages'])(state),
      routeCoefficientsMap: selectors.getRouteCoefficientsMap(selectedRoute)(state),
      selectedRoute,
      locale: selectors.getLocale(state),
      selectedSailPackageIndexToEdit: state.selectedSailPackageIndexToEdit,
      reservationId: selectors.getCurrentReservationId(state),
      isIntermediatePrice,
      vehicleTitles: vehicleTranslations(state),
      vehicleItems: filterItemsByType('VEHICLE')(selectors.getItems(state)),
      trailerItems: filterItemsByType('TRAILER')(selectors.getItems(state)),
      isLaaksaare,
      editDataRequestAllocateError,
      vehicleNumbers: getVehiclePlateNumbers(state),
      isCompanyLoyaltiesEnabled: getEnableCompanyLoyaltiesForRoute(selectedRoute)(state),
      isHandicappedVehiclesEnabled: getEnableHandicappedVehiclesForRoute(selectedRoute)(state),
      currentVehicles: currentVehiclesFormatted,
      lastReqError,
      isReservationForDriverFromReserve: getReservationFromReserve(state),
      currentItems,
      shouldReplaceVehicleWidth,
    }
  },
  { changeItemQtty, changeReduxFormField: change }
)(SimplifiedForm)
