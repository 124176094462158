import React from 'react'
import { map, compose, any, prop, pathOr, equals } from 'ramda'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIButton, UIIcon } from 'bora-material-ui'
import messages from '../../../../consts/messages'
import { cannotCancel, cannotEdit } from '../../../../utils/allowance-util'
import { getStyle, isLiinilaevad } from '../../../../utils/liinilaevadThemeUtils'
import UIElements from '../../../../consts/UIElements'

export const isReservationForCode = (routeCode) =>
  compose(
    any((code) => code.includes(routeCode)),
    map(prop('code')),
    prop('sailPackages')
  )

export const isLaaksaareReservation = isReservationForCode('LAA')
export const isRingsuReservation = isReservationForCode('RIN')

export const isProduction = () => window.brandProps && window.brandProps.env !== 'development'

const noop = () => {}

const buttons = [
  { icon: 'iconDownload', message: messages.download },
  { icon: 'iconPrinter', message: messages.printout },
  { icon: 'iconPencil', message: messages.edit },
  { icon: 'iconClose', message: messages.remove, id: 'removeButton' },
]

const linkStyles = { 'text-decoration': 'none', display: 'flex', 'align-items': 'center' }

const xsStyles = {
  column: { column: true },
  margin0: { margin: '0' },
  marginRight: { marginRight: '10%' },
  rowSpaceAround: { row: true, column: false, justify: 'space-around', margin: '0' },
  prevTitleBlock: { margin: '12px 0', style: { order: 3 } },
}

const filterButtons = (status, additionalStatues = []) => {
  const statuses = ['OK', ...additionalStatues]
  if (!statuses.includes(status)) {
    return buttons.filter((button) => button.icon === 'iconDownload' || button.icon === 'iconPrinter')
  }
  return [...buttons]
}

const areSailsAllowTo = (param = 'cancel') =>
  compose(any(equals(true)), map(pathOr(true, ['sailRefs', [0], 'allowance', param])), prop('sailPackages'))

const ActionsForResults = (
  { reservation, locale, isLoggedIn, showEdit = false, hideRemove, ...props },
  { muiTheme }
) => {
  const { reservationId, token = '' } = reservation

  const allowToCancel = areSailsAllowTo('cancel')(reservation)
  const allowToEdit = areSailsAllowTo('edit')(reservation)

  const isLaak = isLaaksaareReservation(reservation)
  const isRin = isRingsuReservation(reservation)

  const noEdit = UIElements.NO_RES_EDIT || false
  const noRefund = UIElements.NO_RES_REFUND || false

  const additionalStatusesToRender = isLaak || isRin ? ['BOOKED'] : []
  const renderButtons = map(({ icon, message, print = icon === 'iconPrinter', id = 'buttonStyle' }) => {
    const action = () => {
      switch (icon) {
        case 'iconPrinter': {
          window.frames['ticket-frame'].print()
          break
        }

        case 'iconDownload': {
          window.open(`/reservation/${locale}/${reservationId}/confirmation/preview?token=${token}`, '_blank')
          break
        }

        case 'iconPencil': {
          props.goToEditReservation({ reservation, mode: 'all' })
          props.startEditReservation(reservation.token)
          break
        }

        case 'iconClose': {
          props.goToRefund(reservation)
          break
        }

        // eslint-disable-next-line no-empty
        default: {
        }
      }
    }
    let shouldDisabledButton = false
    if (icon === 'iconPencil') {
      if (!showEdit || noEdit) return null
      if (reservation.status === 'BOOKED' && isRin) return null
      shouldDisabledButton = cannotEdit(reservation)

      if (shouldDisabledButton || !allowToEdit) return null
    }
    if (icon === 'iconClose' && reservation.status !== 'OK') {
      if (!(reservation.status === 'BOOKED' && (isLaak || isRin))) return null
    }
    if (icon === 'iconClose' && hideRemove) return null

    if (icon === 'iconClose') {
      if (noRefund) return null
      shouldDisabledButton = cannotCancel(reservation)

      if (shouldDisabledButton || !allowToCancel) return null
    }

    return (
      <UILayout center width="auto" xs={print ? 0 : 1} data-test="action-button-wrapper">
        <UILayout target="new" style={linkStyles} onClick={shouldDisabledButton ? noop : action}>
          {muiTheme.actionButtonsWithText ? (
            <UILayout j-flex-center center cursor="pointer" {...muiTheme.ids.actionButtonsStyle}>
              <UIIcon
                type={icon}
                width={getStyle('20px')}
                height={getStyle('20px')}
                fill={getStyle(icon === 'iconClose' ? '#D11800' : '#3387CC', 'white')}
              />
              <UIText
                margin="0px 0px 0px 10px"
                textTransform={getStyle('capitalize', 'uppercase')}
                size="18px"
                weight={getStyle('bold')}
                color={getStyle(icon === 'iconClose' ? '#D11800' : '#3387CC', '#FFF')}
                font={muiTheme.secondFontFamilyDem}
                translate={message}
              />
            </UILayout>
          ) : (
            <React.Fragment>
              <UILayout
                column
                center
                j-flex-center
                margin={(isLoggedIn && reservation.status !== 'BOOKED') || isLaak ? '0 20px 0 0' : '0 30px 0 0'}
                xs={xsStyles.marginRight}
              >
                <UILayout width="auto">
                  <UIButton
                    disabled={shouldDisabledButton}
                    background={muiTheme.ids.buttonStyles && muiTheme.ids.buttonStyles.background}
                    id={id}
                    type="circle"
                    width="50px"
                    height="50px"
                    data-testid={`button-${icon.replace('icon', '').toLowerCase()}`}
                  >
                    <UIIcon type={icon} />
                  </UIButton>
                </UILayout>
                <UIText
                  margin="10px 0 0 0"
                  textTransform="uppercase"
                  size="12px"
                  color="#79909b"
                  font={muiTheme.secondFontFamilyDem}
                  translate={message}
                />
              </UILayout>
            </React.Fragment>
          )}
        </UILayout>
      </UILayout>
    )
  })

  const actionButtonsStyles = {
    'j-space-between': false,
    'j-flex-start': !isLiinilaevad,
  }

  return (
    <UILayout column>
      <UILayout justify="space-between" flex-start xs={xsStyles.column} {...muiTheme.ids.actionRowButtonsWrapperStyle}>
        <UILayout
          onClick={() => props.goToPage('/profile/tickets')}
          display-if={isLoggedIn}
          j-flex-center
          width="auto"
          data-test="go-to-prev"
          xs={xsStyles.prevTitleBlock}
          style={{ ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.backButton }) }}
        >
          <UILayout cursor="pointer" center j-flex-center xs={xsStyles.margin0}>
            <UIIcon fill="#79909B" height="29px" type="iconGoBack" />
            <UIText
              whiteSpace="nowrap"
              margin="0 0 0 20px"
              letterSpacing="0.5px"
              color="#79909b"
              size="18px"
              textTransform="uppercase"
              font="GinesoNorm"
              xs={xsStyles.prevTitleText}
              translate={messages.backToTickets}
              font={
                muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.titles
                  ? muiTheme.ids.buttonActionStyles.titles.fontFamily
                  : muiTheme.secondFontFamilyDem
              }
              {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
            />
          </UILayout>
        </UILayout>

        <UILayout
          justify={getStyle('space-around', 'flex-end')}
          flex-start
          margin="0px 0px 20px 20px"
          xs={xsStyles.rowSpaceAround}
          {...actionButtonsStyles}
          data-test="action-buttons"
        >
          {renderButtons(filterButtons(reservation.status, additionalStatusesToRender))}
        </UILayout>
      </UILayout>
      <iframe
        name="ticket-frame"
        id="ticket-frame"
        title="ticket-frame"
        style={{ display: 'none' }}
        src={`/reservation/${locale}/${reservationId}/confirmation/preview?token=${token}`}
      />
    </UILayout>
  )
}

ActionsForResults.contextTypes = {
  muiTheme: PropTypes.object,
}

ActionsForResults.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  hideRemove: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  reservation: PropTypes.object.isRequired,
  goToPage: PropTypes.func.isRequired,
  goToRefund: PropTypes.func.isRequired,
  goToEditReservation: PropTypes.func.isRequired,
}

export default ActionsForResults
